import React from 'react'
import { graphql, useStaticQuery } from "gatsby";
import useRichText from "../hooks/useRichTexts";
import Layout from '../fragments/layout/Layout'

const usePrivacidad = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPrivacidad {
        edges {
          node {
            privacidadTexto {
              raw
            }
          }
        }
      }
    }
  `)

  return data
}

const Privacidad = () => {
  const data = usePrivacidad()

  return (
    <Layout>
      <div className="legal-privacy">
        <h1>Política de privacidad</h1>
        <p>{ useRichText(data.allContentfulPrivacidad.edges[0].node.privacidadTexto) }</p>
      </div>
    </Layout>
  )
}

export default Privacidad
